import {
	country,
	locale as localeStore,
	type SupportedCountries,
	type SupportedLocales,
} from '@store/user';

type Props = {
	locale: SupportedLocales;
	countryCode: SupportedCountries;
};
export function LocaleSetter({ locale, countryCode }: Props) {
	localeStore.set(locale);
	country.set(countryCode);
	return null;
}
